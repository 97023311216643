var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:"header_left"},[_c('div',{staticClass:"bg-model"},[_c('div',{staticClass:"content"},[_c('img',{staticClass:"back",attrs:{"src":require("../../assets/img/back.png"),"alt":""}}),_vm._m(1),_c('ul',[_c('a',{staticClass:"fs",on:{"click":function($event){return _vm.linkToPage(1)}}},[_vm._m(2)]),_c('a',{staticClass:"fs",on:{"click":function($event){return _vm.linkToPage(2)}}},[_vm._m(3)]),_c('a',{staticClass:"fs",on:{"click":function($event){return _vm.linkToPage(3)}}},[_vm._m(4)]),_c('a',{staticClass:"fs",on:{"click":function($event){return _vm.linkToPage(4)}}},[_vm._m(5)]),_c('a',{staticClass:"fs",on:{"click":function($event){return _vm.linkToPage(5)}}},[_vm._m(6)])])])])]),_vm._m(7),_c('div',{staticClass:"curriculum"},[_vm._m(8),_c('div',{staticClass:"lore"},[_c('div',{staticClass:"video_one"},[_c('div',{staticClass:"video_bg"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShow),expression:"!isShow"}],staticClass:"video_img",on:{"click":_vm.playVideo}},[_c('span',{staticClass:"video_play"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticClass:"video_open"},[_c('video',{ref:"videoPlayer",attrs:{"preload":"","disablepictureinpicture":"","controlslist":"nodownload","controls":"","width":"550","height":"300","src":"http://file.zjxk12.com/mp4/1568712089502.mp4"}})])])])])]),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_c('footerEn')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('div',{staticStyle:{"width":"100%","display":"flex"}},[_c('img',{staticClass:"sort",attrs:{"src":require("../../assets/img/分类.png"),"alt":""}}),_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/img/zuimeikebenlogo.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('img',{staticClass:"logo_big",attrs:{"src":require("../../assets/img/zuimeikebenlogo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"clearfix",staticStyle:{"padding-top":".3rem"}},[_c('img',{staticStyle:{"width":".44rem","height":".44rem","padding-left":".26rem"},attrs:{"src":require("../../assets/img/课程.png"),"alt":""}}),_c('span',{staticClass:"fs left_text",staticStyle:{"padding-left":".37rem"}},[_vm._v(" 最美课本 ")]),_c('img',{staticClass:"more",attrs:{"src":require("../../assets/img/more.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"clearfix"},[_c('img',{staticStyle:{"width":".44rem","height":".44rem","padding-left":".26rem"},attrs:{"src":require("../../assets/img/公益.png"),"alt":""}}),_c('span',{staticClass:"fs left_text",staticStyle:{"padding-left":".37rem"}},[_vm._v(" 教育公益 ")]),_c('img',{staticClass:"more",attrs:{"src":require("../../assets/img/more.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"clearfix"},[_c('img',{staticStyle:{"width":".44rem","height":".44rem","padding-left":".26rem"},attrs:{"src":require("../../assets/img/合作.png"),"alt":""}}),_c('span',{staticClass:"fs left_text",staticStyle:{"padding-left":".31rem"}},[_vm._v(" 合作共赢 ")]),_c('img',{staticClass:"more",attrs:{"src":require("../../assets/img/more.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"clearfix"},[_c('img',{staticStyle:{"width":".44rem","height":".44rem","padding-left":".26rem"},attrs:{"src":require("../../assets/img/新闻.png"),"alt":""}}),_c('span',{staticClass:"fs left_text",staticStyle:{"padding-left":".33rem"}},[_vm._v(" 公司新闻 ")]),_c('img',{staticClass:"more",attrs:{"src":require("../../assets/img/more.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"clearfix"},[_c('img',{staticStyle:{"width":".44rem","height":".44rem","padding-left":".26rem"},attrs:{"src":require("../../assets/img/关于我们.png"),"alt":""}}),_c('span',{staticStyle:{"padding-left":".34rem"}},[_vm._v(" 关于我们 ")]),_c('img',{staticClass:"more",attrs:{"src":require("../../assets/img/more.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mid"},[_c('div',{staticClass:"present"},[_c('div',{staticClass:"graphic"},[_c('span',{staticClass:"course fb"},[_vm._v(" 让课程学习 ")]),_c('span',{staticClass:"interesting fb"},[_vm._v(" 有趣味 变美好 ")])]),_c('div',{staticClass:"study fs"},[_vm._v(" 学生应景学、趣味学、自主学 ")]),_c('div',{staticStyle:{"margin-top":".2rem","padding-left":"5%","text-align":"center"}},[_c('div',{staticClass:"photo"},[_c('img',{staticClass:"boy",attrs:{"src":require("../../assets/img/img_boy.png"),"alt":""}}),_c('img',{staticClass:"girl",attrs:{"src":require("../../assets/img/img_girl.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"curriculum_header"},[_c('p',{staticClass:"primary fb"},[_vm._v("小学最美课本")]),_c('span',{staticClass:"passive fb"},[_vm._v(" 让学生从被动学习变为 ")]),_c('span',{staticClass:"initiative fb"},[_vm._v(" 主动学习 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro"},[_c('div',{staticClass:"intro_list"},[_c('ul',[_c('li',{staticStyle:{"padding-left":".2rem"}},[_c('img',{attrs:{"src":require("../../assets/img/课程优势.png"),"alt":""}}),_c('p',{staticClass:"fb"},[_vm._v("课程优势")]),_c('span',{staticClass:"fs"},[_vm._v("同步教材、双语配音、应景学习、美育 ")])]),_c('li',[_c('img',{attrs:{"src":require("../../assets/img/学科分类.png"),"alt":""}}),_c('p',{staticClass:"fb"},[_vm._v("学科分类")]),_c('span',{staticClass:"fs"},[_vm._v("语文 数学 英语")])]),_c('li',[_c('img',{attrs:{"src":require("../../assets/img/年级课程.png"),"alt":""}}),_c('p',{staticClass:"fb"},[_vm._v("年级课程")]),_c('span',{staticClass:"fs"},[_vm._v("小学1-6年级")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"know"},[_c('div',{staticClass:"know_content"},[_c('div',{staticClass:"publicize"},[_c('span',{staticClass:"experience fb"},[_vm._v(" 让孩子体会到：原来学习是一件很 ")]),_c('span',{staticClass:"fine fb",staticStyle:{"font-size":".4rem"}},[_vm._v(" 美好 ")]),_c('span',{staticClass:"thing fb"},[_vm._v(" 的事情 ")]),_c('p',{staticClass:"textbook fs"},[_vm._v("让学生从被动学习变为主动学习")])]),_c('ul',{staticClass:"oul"},[_c('li',{staticStyle:{"padding-bottom":".2rem"}},[_c('div',{staticClass:"title_top"},[_c('img',{staticClass:"orange",attrs:{"src":require("../../assets/img/img_orange.png"),"alt":""}})]),_c('div',{staticClass:"title_bottom"},[_c('p',{staticClass:"nice fb"},[_vm._v("体验美")]),_c('p',{staticClass:"wire"}),_c('span',{staticClass:"explain fs"},[_vm._v(" 学生通过听觉、 视觉、 思考 “全感官”学习 ")])])]),_c('li',{staticStyle:{"padding-bottom":".2rem"}},[_c('div',{staticClass:"title_content"},[_c('img',{staticClass:"orange",attrs:{"src":require("../../assets/img/img_yellow.png"),"alt":""}})]),_c('div',{staticClass:"title_bottom"},[_c('p',{staticClass:"nice fb"},[_vm._v("创造美")]),_c('p',{staticClass:"wire"}),_c('span',{staticClass:"explain fs"},[_vm._v(" 学生可根据课程绘画、创作、 朗读、配音 ")])])]),_c('li',[_c('div',{staticClass:"title_right"},[_c('img',{staticClass:"orange",attrs:{"src":require("../../assets/img/img_blue.png"),"alt":""}})]),_c('div',{staticClass:"title_bottom"},[_c('p',{staticClass:"nice fb",staticStyle:{"padding":".4rem 0 .18rem 0"}},[_vm._v("分享美")]),_c('p',{staticClass:"wire"}),_c('span',{staticClass:"result fs"},[_vm._v(" 学生将学习成果 可以分享 给家长、 同学、老师，或者 朋友圈 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"culture"},[_c('div',{staticClass:"culture_content"},[_c('span',{staticClass:"thought fb"},[_vm._v(" 全面培养学生的 ")]),_c('span',{staticClass:"creativity fb"},[_vm._v(" 想象力 ")]),_c('span',{staticClass:"thought fb"},[_vm._v(" 个性化 ")]),_c('br'),_c('span',{staticClass:"creativity fb"},[_vm._v(" 思维能力 ")]),_c('span',{staticClass:"thought fb"},[_vm._v(" 和 ")]),_c('span',{staticClass:"creativity fb"},[_vm._v(" 创造力 ")])]),_c('ul',{staticClass:"clearfix"},[_c('li',{staticClass:"fl"},[_c('div',{staticClass:"learn_top_one fb"},[_vm._v(" 学的快 ")]),_c('div',{staticClass:"learn_bottom_one"},[_c('p',{staticClass:"fs",staticStyle:{"padding-top":".26rem"}},[_vm._v("课前预习")]),_c('p',{staticClass:"fs"},[_vm._v("课中学习")]),_c('p',{staticClass:"fs"},[_vm._v("课后复习")])])]),_c('li',{staticClass:"fl",staticStyle:{"padding-top":".01rem"}},[_c('div',{staticClass:"learn_top_two fb"},[_vm._v(" 学的快 ")]),_c('div',{staticClass:"learn_bottom_two"},[_c('p',{staticClass:"fs",staticStyle:{"padding-top":".26rem"}},[_vm._v("三减负：")]),_c('p',{staticClass:"fs"},[_vm._v("为学生、家长、老师减负")])])]),_c('li',{staticClass:"fl",staticStyle:{"padding-top":".36rem"}},[_c('div',{staticClass:"learn_top_three fb"},[_vm._v(" 学的快 ")]),_c('div',{staticClass:"learn_bottom_three"},[_c('p',{staticClass:"fs",staticStyle:{"padding-top":".26rem"}},[_vm._v("分享学习成果给老师、")]),_c('p',{staticClass:"fs"},[_vm._v("同学、家长、朋友圈")])])]),_c('li',{staticClass:"fl",staticStyle:{"padding-top":".37rem"}},[_c('div',{staticClass:"learn_top_four fb"},[_vm._v(" 学的快 ")]),_c('div',{staticClass:"learn_bottom_four"},[_c('p',{staticClass:"fs",staticStyle:{"padding-top":".26rem"}},[_vm._v("学生挣学习积分，")]),_c('p',{staticClass:"fs"},[_vm._v("可以获得奖励")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"down_page"},[_c('div',{staticClass:"down_info"},[_c('div',{staticClass:"app_info clearfix"},[_c('p',{staticClass:"app_title fb"},[_vm._v("再佳学最美课本APP")]),_c('p',{staticClass:"client fs"},[_vm._v("专属手机客户端")]),_c('div',{staticClass:"fl",staticStyle:{"width":"60%"}},[_c('div',{staticClass:"ipone_down"},[_c('div',{staticClass:"ios_info"},[_c('img',{staticClass:"ios",attrs:{"src":require("../../assets/img/icon_ios.png"),"alt":""}}),_c('span',{staticClass:"ios_title fs"},[_vm._v("iOS苹果端下载")])])]),_c('div',{staticClass:"android_down"},[_c('div',{staticClass:"and_info"},[_c('img',{staticClass:"android",attrs:{"src":require("../../assets/img/icon_andriod.png"),"alt":""}}),_c('span',{staticClass:"android_title fs",staticStyle:{"padding-top":".02rem"}},[_vm._v("Android安卓端下载")])])])]),_c('img',{staticClass:"img_code fl",attrs:{"src":require("../../assets/img/img_QR code.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }